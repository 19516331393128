import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/terms/service",
    name: "termsofservice",
    component: () => import("@/components/pages/terms/TermsOfServiceView.vue"),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/loanreq/service",
    name: "blanktermsofservice",
    component: () => import("@/components/pages/terms/TermsOfServiceView.vue"),
    meta: {
      layout: "blank",
      footer: true,
    },
  },
  {
    path: "/terms/loanreq/stockinfo",
    name: "blanktermsofStockInfo",
    component: () => import("@/components/pages/terms/TermsOfStockInfoView.vue"),
    meta: {
      layout: "blank",
      footer: true,
    },
  },
  {
    path: "/terms/loanreq/psnlinfo",
    name: "blanktermsofPsnlInfo",
    component: () => import("@/components/pages/terms/TermsOfPersonalnfoView.vue"),
    meta: {
      layout: "blank",
      footer: true,
    },
  },
  
  {
    path: "/terms/loanreq/niceInfo",
    name: "blanktermsofNiceInfo",
    component: () => import("@/components/pages/terms/TermsOfNiceInfoView.vue"),
    meta: {
      layout: "blank",
      footer: true,
    },
  },
  {
    path: "/terms/loanreq/stockaccountoper",
    name: "blanktermsofStockAccountOperInfo",
    component: () => import("@/components/pages/terms/TermsOfStockAccountOperView.vue"),
    meta: {
      layout: "blank",
      footer: true,
    },
  },
  {
    path: "/terms/linkedloancontract",
    name: "termsoflinkedloancontract",
    component: () =>
      import("@/components/pages/terms/TermsOfLinkedLoanContractView.vue"),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/loanreq/linkedloancontract",
    name: "blanktermsoflinkedloancontract",
    component: () =>
      import("@/components/pages/terms/TermsOfLinkedLoanContractView.vue"),
    meta: {
      layout: "blank",
      footer: true,
    },
  },
  {
    path: "/terms/linkedivmtcontract",
    name: "termsoflinkedivmtcontract",
    component: () =>
      import("@/components/pages/terms/TermsOfLinkedIvmtContractView.vue"),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/linkedivmtcontractbefore2310",
    name: "termsoflinkedivmtcontractbefore2310",
    component: () =>
      import("@/components/pages/terms/TermsOfLinkedIvmtContractBefore2310.vue"),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },

  {
    path: "/terms/eltcfnnctrst",
    name: "termsofeltcfnnctrst",
    component: () =>
      import("@/components/pages/terms/TermsOfEltcFnncTrstView.vue"),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/personalcreditinfopolicy",
    name: "personalcreditpolicy",
    component: () =>
      import(
        "@/components/pages/terms/TermsOfPersonalCreditInfoProcessingPolicyView.vue"
      ),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/personalinfosys",
    name: "personalinfosys",
    component: () =>
      import(
        "@/components/pages/terms/TermsOfPersonalCreditInfoSys.vue"
      ),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/prsncreditinfoprvdathirdparty",
    name: "prsncreditinfoprvdathirdparty",
    component: () =>
      import(
        "@/components/pages/terms/TermsOfPrsnCreditInfoPrvdAThirdPartyView.vue"
      ),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/prsnregno",
    name: "prsnregno",
    component: () =>
      import(
        "@/components/pages/terms/TermsOfPersonalRegNoView.vue"
      ),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/prsnmarketing",
    name: "prsnmarketing",
    component: () =>
      import(
        "@/components/pages/terms/TermsOfPersonalMarketing.vue"
      ),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
  {
    path: "/terms/codeofethicsforexctsandempls",
    name: "codeofethicsforexctsandempls",
    component: () =>
      import(
        "@/components/pages/terms/TermsOfCodeOfEthicsForExctsAndEmplsView.vue"
      ),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },

  {
    path: "/terms/creditinfoutztsystem",
    name: "creditinfoutztsystem",
    component: () =>
      import("@/components/pages/terms/TermsOfCreditInfoUtztSystemView.vue"),
    meta: {
      layout: "mobile-yes-footer-layout",
      footer: true,
    },
  },
];

export default routes;
